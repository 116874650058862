import React from 'react'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import PageBase from '@/components/common/Page'
import Headline from '@/components/app/Headline'
import SEO from '@/components/head/seo'
import { layouts } from '@/utils/preset'
import { tabletS, mobile, mobileS } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'
import LinkButton1 from '@/components/common/LinkButton1'

const ThanksPage = props => (
  <Root>
    <SEO title="送信完了"/>
    <Head>
      <Title theme={props.theme}>
        THANK YOU
      </Title>
    </Head>
    <Main>
      <Body variant="body1">
        資料請求ありがとうございます。入力いいただいたメールアドレスにダウンロードリンクを送信しました。届いていない場合は、メールアドレス等の入力情報をご確認の上、もう一度フォームより送信頂きますようお願い申し上げます。
      </Body>
    </Main>
    <Foot>
      <MoreButton to="/" component={Link} color="primary">サイトトップへ</MoreButton>
    </Foot>
  </Root>
)

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const Head = styled.div``

const Title = styled(Headline)`
  strong {
    color: ${props => props.theme.primary.base};
  }
  @media ${mq.and(mobileS)} {
    br:nth-of-type(2) {
      display: none;
    }
  }
`

const Main = styled.div`
  margin-top: 40px;
  @media ${mq.and(mobile)} {
    margin-top: 32px;
  }
`

const Body = styled(Typography)`
  margin: 0 auto;
  max-width: ${layouts.defs.contentMaxWidth.base}px;
  text-align: center;
  @media ${mq.and(tabletS)} {
    text-align: left;
    max-width: ${layouts.defs.contentMaxWidth.small}px;
    br {
      display: none;
    }
  }
  @media ${mq.and(mobile)} {
    text-align: left;
    br {
      display: none;
    }
  }
`

const Foot = styled.div`
  margin-top: 48px;
  display: flex;
  justify-content: center;
`

const MoreButton = styled(LinkButton1)`
  width: 278px;
  @media ${mq.and(mobileS)} {
    width: 100%;
  }
`

export default PageBase(ThemeConsumer(ThanksPage))
